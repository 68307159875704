import React from 'react';
import styled from 'styled-components';
import {
  SiTiktok,
  SiInstagram,
  SiFacebook,
  SiTwitter,
  SiYoutube,
  SiTwitch,
  SiSnapchat,
  SiOnlyfans,
  SiPatreon,

} from 'react-icons/si';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { GrMenu, GrClose } from 'react-icons/gr';
import Clips4sale from '../../images/social-icons/clips4sale.png';
import manyvids from '../../images/social-icons/manyvids-pink.png';
import chaturbate from '../../images/social-icons/chaturbate-pink.svg';

import fetlife from '../../images/social-icons/fetlife.png';
import Close from '../../images/close.svg';
import Open from '../../images/hamburg.svg';

  // import VIP1 from '../../images/vip/VIP1.jpg';
  // import VIP2 from '../../images/vip/VIP2.jpg';
  // import VIP3 from '../../images/vip/VIP3.jpg';
  // import VIP4 from '../../images/vip/VIP4.jpg';
  // import VIP5 from '../../images/vip/VIP5.jpg';
  // import VIP6 from '../../images/vip/VIP6.jpg';
  // import VIP7 from '../../images/vip/VIP7.jpg';
  // import VIP8 from '../../images/vip/VIP8.jpg';
  // import VIP9 from '../../images/vip/VIP9.jpg';
  // import VIP10 from '../../images/vip/VIP10.jpg';
  // import VIP11 from '../../images/vip/VIP11.jpg';
  // import VIP12 from '../../images/vip/VIP12.jpg';
  // import VIP13 from '../../images/vip/VIP13.jpg';
  // import VIP14 from '../../images/vip/VIP14.jpg';
// import X from '../../images/close.svg';
export const PlayButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: fit-content;
  padding: 0.5rem;
  background: none;
  border: 2px solid #ca3994;
  color: #ca3994;
  font-style: 'kabel-heavyheavy', sans-serif;
  font-size: 1.2rem;
  white-space: nowrap;
  &:hover {
    color: #f5c6e2;
  }ß
`;
export const Button = styled.button`
  margin: 0 auto;
  background-color: transparent;
  border: none;
  font-style: 'kabel-heavyheavy', sans-serif;
  font-size: 3rem;
`;
export const CloseX = styled(AiOutlineClose)`
  margin: 0 auto;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  color: #ca3994;
  padding: 0;
  width: 2rem;
  height: 2rem;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;
export const OpenMenu = styled(AiOutlineMenu)`
  margin: 0 auto;
  background: transparent;
  border: none;
  color: #ca3994;
  font-size: 1.5rem;
  padding: 0;
  width: 2rem;
  height: 2rem;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const CloseMenuIcon = styled(GrClose)`
  fill: currentColor;
  stroke: currentColor;
  background-color: transparent;
  color: #fff;
  font-size: 3rem;
`;
export const OpenMenuIcon = styled(GrMenu)`
  color: #fff;
  background-color: transparent;
  font-size: 3rem;
`;
export const TiktokIcon = styled(SiTiktok)`
  font-size: ${props => props.size || '1rem'};
`;
export const InstagramIcon = styled(SiInstagram)`
  font-size: ${props => props.size || '1rem'};
`;
export const FacebookIcon = styled(SiFacebook)`
  font-size: ${props => props.size || '1rem'};
`;
export const TwitterIcon = styled(SiTwitter)`
  font-size: ${props => props.size || '1rem'};
`;
export const YoutubeIcon = styled(SiYoutube)`
  font-size: ${props => props.size || '1rem'};
`;
export const TwitchIcon = styled(SiTwitch)`
  font-size: ${props => props.size || '1rem'};
`;
export const SnapchatIcon = styled(SiSnapchat)`
  font-size: ${props => props.size || '1rem'};
`;
export const OnlyfansIcon = styled(SiOnlyfans)`
  font-size: ${props => props.size || '1rem'};
`;
export const PatreonIcon = styled(SiPatreon)`
  font-size: ${props => props.size || '1rem'};
`;
export const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.2rem;
  height: 2.2rem;
`;
export const StyledImageIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  height: ${props => props.size || '1rem'};
  img {
    width: auto;
    height: 200%;
    margin: .5rem;
  }
`;
export const StyledImageIconRounded = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.size || '1rem'};
  padding: 0.5rem;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;
export const FetLifeIcon = () => (
  <StyledImageIconRounded>
    <img src={fetlife} alt='fetlife' />
  </StyledImageIconRounded>
);
export const ManyvidsIcon = () => (
  <StyledImageIconRounded>
    <img src={manyvids} alt="manyvids" />
  </StyledImageIconRounded>
);
export const Clips4saleIcon = () => (
  <StyledImageIcon>
    <img src={Clips4sale} alt="Clips4sale" />
  </StyledImageIcon>
);
export const ChaturbateIcon = () => (
  <StyledImageIcon>
    <img src={chaturbate} alt="chaturbate" />
  </StyledImageIcon>
);


