import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
// import 'output.scss';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
const Gallery = lazy(() => import('./components/GalleryHome/Gallery'));
const GalleryList = lazy(() => import('./components/GalleryHome/GalleryList'));
const GalleryHome = lazy(() => import('./components/GalleryHome'));
const VideoViewer = lazy(() => import('./components/VideoHome/VideoViewer'));
const VideoHome = lazy(() => import('./components/VideoHome'));
const VideoList = lazy(() => import('./components/VideoHome/VideoList'));
const VIPHome = lazy(() => import('./components/VIPHome'));
const Public = lazy(() => import('./components/Appearances'));
// const Social = lazy(() => import('./components/Social'));
const Biography = lazy(() => import('./components/Biography'));
const Home = lazy(() => import('./components/Home'));
const rootElement = document.getElementById('root');
ReactDOM.render(
  <BrowserRouter>
    {/* basename="public_build" basename="public_build".sort((a, b) => a -b ) */}
    <Suspense fallback={<div className="loading">Loading...</div>}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="biography" element={<Biography />} />
          {/* <Route path="social" element={<Social />} /> */}
          <Route path="appearances" element={<Public />} />
          <Route path="vip" element={<VIPHome />}>
            <Route path="galleries" element={<GalleryHome />}>
              <Route index element={<GalleryList />} />
              <Route path=":galleryName" element={<Gallery />} />
            </Route>
            <Route path="videos" element={<VideoHome />}>
              <Route index element={<VideoList />} />
              <Route path=":videoId" element={<VideoViewer />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  </BrowserRouter>,
  rootElement
);
