import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import {useDetectOutsideClick} from './useDetectOutsideClick'
import { YoutubeIcon } from '../Styled';
import { Button, CloseX, OpenMenu } from '../Styled';

const Navigation = (props) => {
  const [open, setOpen] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const setBoth = () => {
    setOpen(!open);
    setIsActive(!isActive);
  };
  const onClick = () => setIsActive(true);
  const onMouseOver = () => setIsActive(true); //sets the vip dropdown to active on mouse over
  const onMouseOut = () => setIsActive(false); //sets the vip dropdown to inactive on mouse out
  //this sets the dimensions of the window and refreshes the dom on resize.
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
      setIsActive(false);
    }
    window.addEventListener('resize', handleResize);
    return (_) => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return dimensions.width > 800 ? (
    ///////////// desktop/ipad if the window width is greater than than 800px return this
    <div className="navbar">
      <ul>
        <li>
          <Link to={'/'}>Home</Link>
        </li>
        <li>
          <Link to={'biography'}>Biography</Link>
        </li>
        <li>
          <Link to={'appearances'}>Public&nbsp;Appearances</Link>
        </li>
        <li>
          <a
            href="https://www.youtube.com/user/taraemory"
            target="_blank"
            rel="noopener noreferrer"
            alt="Everything's Tarable">
            {' '}
            <span className="youtube-nav">
              <YoutubeIcon />
            </span>
            Everything's&nbsp;Tarable
          </a>
        </li>
        <li className={`menu-container`}>
          {/* //sub-navigation */}
          <a
            onMouseOver={onMouseOver}
            href="http://tara-ts.com"
            target="_blank"
            rel="noopener noreferrer"
            alt="VIP">
            {' '}
            VIP
          </a>
          <div className="eighteen">18+</div>
          {isActive ? (
            <span className={`vip-menu-toggle vip-close`}>
              <CloseX onClick={onMouseOut} onMouseOver={onMouseOver} />
            </span>
          ) : (
            <span className={`vip-menu-toggle`}>
              <OpenMenu onClick={onMouseOver} />
            </span>
          )}

          <div
            onMouseOver={onMouseOver}
            className={`menu ${isActive ? 'active' : 'inactive'}`}>
            <a
              onClick={onMouseOut}
              href="http://tara-ts.com"
              target="_blank"
              rel="noopener noreferrer"
              alt="VIP">
              Galleries
            </a>

            {/* <Link to={`/vip/galleries`}>Galleries</Link> */}
            <a
              onClick={onMouseOut}
              href="http://tara-ts.com"
              target="_blank"
              rel="noopener noreferrer"
              alt="VIP">
              Vidoes
            </a>
            {/* <Link to={`/vip/videos`}>Videos</Link> */}
            <a
              onClick={onMouseOut}
              href="https://chaturbate.com/taraemory/"
              target="_blank"
              rel="noopener noreferrer"
              alt="Tara on Chaturbate">
              Webcam
            </a>
            <a
              onClick={onMouseOut}
              href="https://tara-ts.com/blog"
              target="_blank"
              rel="noopener noreferrer"
              alt="Tara's Blog'">
              Blog
            </a>
          </div>
        </li>
      </ul>
    </div>
  ) : (
    //////for phones and tabs if the window width is less than 800px return this
    <>
      <div className="hamburg-icon">
        {isActive ? (
          <Button>
            <CloseX onClick={onMouseOut} />
          </Button>
        ) : (
          <Button>
            <OpenMenu onClick={onClick} />
          </Button>
        )}
      </div>
      <div className={`navbar`}>
        <ul className={`menu ${isActive ? 'active' : 'inactive'}`}>
          <li>
            <Link onClick={onMouseOut} to={'/'}>
              Home
            </Link>
          </li>
          <li>
            <Link onClick={onMouseOut} to={'biography'}>
              Biography
            </Link>
          </li>
          <li>
            <Link onClick={onMouseOut} to={'appearances'}>
              Public&nbsp;Appearances
            </Link>
          </li>
          <li>
            <a
              onClick={onMouseOut}
              href="https://www.youtube.com/user/taraemory"
              target="_blank"
              rel="noopener noreferrer"
              alt="Everything's Tarable">
              {' '}
              Everything's&nbsp;Tarable
              <span className="youtube-nav">
                <YoutubeIcon />
              </span>
            </a>
          </li>
          <li>
            {/* /////sub-navigation */}
            <div className={`menu-container`}>
              <a
                href="http://tara-ts.com"
                target="_blank"
                rel="noopener noreferrer"
                alt="VIP"
                onClick={onMouseOut}>
                {' '}
                VIP
                <div className="eighteen">18+</div>
              </a>
              <div className={`menu active`}>
                <a
                  href="http://tara-ts.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="VIP">
                  Galleries
                </a>
                <a
                  onClick={onMouseOut}
                  href="http://tara-ts.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="VIP">
                  Vidoes
                </a>
                {/* <Link to={`/vip/videos`}>Videos</Link>*/}
                <a
                  href="https://chaturbate.com/taraemory/"
                  target="_blank"
                  onClick={onMouseOut}
                  rel="noopener noreferrer"
                  alt="Tara on Chaturbate">
                  Webcam
                </a>
                <a
                  href="https://tara-ts.com/blog"
                  target="_blank"
                  onClick={onMouseOut}
                  rel="noopener noreferrer"
                  alt="Tara's Blog'">
                  Blog
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Navigation;
// const Navigation = (props) => { onClick={setOpen(!open)}
//   const [open, setOpen] = useState(false);
//   // const screenWidth = window.innerWidth;

//   const dropdownRef = useRef(null);
//   const [isActive, setIsActive] = useDetectOutsideClick  onClick={setOpen(false)} (dropdownRef, false);
//   const setBoth = ()  => {
//     setOpen(!open);
//     setIsActive(!isActive);
//   };
//   const onClick = () => setOpen(!open);//toggles the navbar open or closed and sets vip dropdown to true
//   const onMouseOver = () => setIsActive(true);//sets the vip dropdown to active on mouse over
//   const onMouseOut = () => setIsActive(!isActive);//sets the vip dropdown to inactive on mouse out
//   //this sets the dimensions of the window and refreshes the dom on resize.
//   const [dimensions, setDimensions] = useState({
//     height: window.innerHeight,
//     width: window.innerWidth,
//   });
//   React.useEffect(() => {
//     function handleResize() {
//       setDimensions({
//         height: window.innerHeight,
//         width: window.innerWidth,
//       });
//     }
//     window.addEventListener('resize', handleResize);
//     return (_) => {
//       window.removeEventListener('resize', handleResize);
//     };
//   });
//   return (
//     <>
//       <div
//         onClick={setBoth}
//         className={
//           dimensions.width > 799
//             ? 'navbar open'
//             : `${!open ? 'navbar open' : 'navbar close'}`
//         }>
//         <ul>
//           <li>
//             <Link to={'/'}>Home</Link>
//           </li>
//           <li>
//             <Link to={'biography'}>Biography</Link>
//           </li>
//           <li>
//             <Link to={'appearances'}>Public&nbsp;Appearances</Link>
//           </li>
//           <li>
//             <a
//               href="https://www.youtube.com/user/taraemory"
//               target="_blank"
//               rel="noopener noreferrer"
//               alt="Everything's Tarable">
//               {' '}
//               <span className="youtube-nav">
//                 <YoutubeIcon />
//               </span>
//               Everything's&nbsp;Tarable
//             </a>
//           </li>
//           <li>
//             {/* //sub-navigation */}
//             <div className={`menu-container`}>
//               <a
//                 href="http://tara-ts.com"
//                 onMouseOver={dimensions.width > 799 ? onMouseOver : null}
//                 onClick={dimensions.width > 799 ? onMouseOut : null}

//                 ref={dropdownRef}
//                 target="_blank" rel="noopener noreferrer" alt="VIP"> VIP
//                 <div className="eighteen">18+</div>
//               </a>
//               <div
//                 onMouseOver={dimensions.width > 799 ? onMouseOver : null}
//                 onMouseOut={dimensions.width > 799 ? onMouseOut : null}
//                 ref={dropdownRef}
//                 className={`menu ${isActive ? 'active' : 'inactive'}`}>
//                 <a
//                   href="http://tara-ts.com"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   alt="VIP">
//                   Galleries
//                 </a>
//                 {/* <Link to={`/vip/galleries`}>Galleries</Link> */}
//                 <a
//                   href="http://tara-ts.com"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   alt="VIP">
//                   Vidoes
//                 </a>
//                 {/* <Link to={`/vip/videos`}>Videos</Link> */}
//                 <a
//                   href="https://chaturbate.com/taraemory/"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   alt="Tara on Chaturbate">
//                   Webcam
//                 </a>
//                 <a
//                   href="https://tara-ts.com/blog"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   alt="Tara's Blog'">
//                   Blog
//                 </a>
//               </div>
//             </div>
//           </li>
//         </ul>
//       </div>
//       <div className="hamburg-icon">
//         {open ? (
//           <Button onClick={setBoth}>
//             <OpenMenu />
//           </Button>
//         ) : (
//           <Button onClick={setBoth}>
//             <CloseX />
//           </Button>
//         )}
//       </div>
//     </>
//   );
// }
// export default Navigation;
