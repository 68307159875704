import React,{ useState } from 'react';
import { Outlet } from 'react-router';
import Navigation from './components/Navigation';
import './output.css';


const App = () => {
  return (
    <>
      <header>
        <Navigation />
      </header>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default App;
